import Typography from "@mui/material/Typography";
import { NextPage } from "next";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from "swiper/react";
import Image from 'next/image';
import { Pagination } from "swiper"
import { modals } from "./modal/Modals";
import useModals from "./modal/useModals";
import { observer } from "mobx-react-lite";
import PageStore from "stores/PageStore";
import EventType from "types/EventType";
import Const from "Const";
import 'swiper/css/pagination';
import 'swiper/css';
import PageMenu from "types/PageMenu";
import Truncate from 'react-truncate';
import update from 'immutability-helper';
import Util from "Util";
import ChannelService from "ChannelService";
import CardNewsSlide from "types/CardNewsSlide";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toast } from 'react-toastify';

interface Props {
  currentPageMenu: PageMenu;
}

const Container = styled.div<{currentPageMenu: PageMenu}>`
  left: ${props => props.currentPageMenu === PageMenu["포트폴리오"] ? '0' : '100vw'};
  right: 0;
  top: 0;
  padding-top: 62px;
  padding-bottom: 82px;
  bottom: 0;
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 364666;
  background-color: white;
  transition: all .46s cubic-bezier(0, 0, 0.2, 1);
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 444px;

  &, & * {
    touch-action: auto;
  }
`;

const MainSwiper = styled(Swiper)`
  overflow: visible !important;
  z-index: 0;
  height: 100%;

  &, & * {
    line-height: 0 !important;
  }

  .swiper-slide {
    // height: 100vw;
    // max-height: 444px;
    position: relative;
  }

  .swiper-pagination {
    bottom: -56px !important;
  }

  .swiper-pagination-bullet {
    background-color: transparent;
  }


  .swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-next-next, .swiper-pagination-bullet-active-prev, .swiper-pagination-bullet-active-prev-prev  {
    background-color: grey;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: #0abab5;
  }
`;


const 세번째페이지: NextPage<Props> = ({currentPageMenu}) => {
  const swiperRef = useRef<any>(Array(PageStore.page?.cardNewsSlideGroups.length).fill(null));
  const [_rerender, _doRerender] = useState({});
  const doRerender = useCallback(() => _doRerender({}), []);
  const {openModal} = useModals();
  const [isDescriptionTruncated, setIsDescriptionTruncated] = useState(PageStore.page?.cardNewsSlideGroups.map(() => true) || null);
  const activeSlide: CardNewsSlide[] | null = useMemo(() => swiperRef.current ? swiperRef.current.map((swiper: any, idx: number) => swiper ? PageStore.page!.cardNewsSlideGroups[idx].cardNewsSlides[swiper.activeIndex] : null) : null, [swiperRef.current, _rerender, PageStore.page]);


  const onCardNewsChange = useCallback(e => {
    PageStore.setActiveCardNewsSlideGroup(PageStore.page!.cardNewsSlideGroups[e.currentTarget.selectedIndex]);
  }, []);

  const openShareModal = useCallback(() => {
    openModal(modals.shareModal, {});
  }, []);

  const open이미지Modal = useCallback((url, isAllowDownload = false) => {
    openModal(modals.이미지Modal, {url, isAllowDownload});
  }, []);
  
  // const doSlideEvent = useCallback(() => {
  //   if (!activeSlide) return;

  //   switch (activeSlide.eventType) {
  //     case (EventType.LINK): {
  //       window.open(activeSlide.eventArgs[0]);
  //       break;
  //     }
  //     case (EventType.OPEN_IMAGE): {
  //       open이미지Modal(activeSlide.eventArgs[0], activeSlide.eventArgs[1]);
  //       break;
  //     }
  //     case (EventType.PHONE_CALL): {
  //       Util.phoneCall(activeSlide.eventArgs[0]);
  //       break;
  //     }
  //     case (EventType.SEND_MESSAGE): {
  //       Util.sendMessage(activeSlide.eventArgs[0], activeSlide.eventArgs[1]);
  //       break;
  //     }
  //     case (EventType.SHARE): {
  //       openShareModal();
  //       break;
  //     }
  //   }
  // }, [activeSlide]);

  const updateIsDescriptionTruncated = useCallback((idx, value) => {
    console.log(idx, value);
    setIsDescriptionTruncated(isDescriptionTruncated =>
      update(isDescriptionTruncated, {
        [idx]: {$set: value}
      })
    )
  }, [isDescriptionTruncated]);

  const openLink = useCallback((link: string) => {
    window.open(link, '_blank');
  }, []);

  const doSlideEvent = useCallback((slide) => {
    switch (slide.eventType) {
      case (EventType.BUY_LINK): {
        openLink(slide.eventArgs[0]);
        break;
      };

      case (EventType.DIRECT_LINK): {
        openLink(slide.eventArgs[0]);
        break;
      };

      case (EventType.SURVEY_LINK): {
        openLink(slide.eventArgs[0]);
        break;
      };
      
      case (EventType.PHONE_CALL): {
        Util.phoneCall(slide.eventArgs[0]);
        break;
      };
      case (EventType.SEND_MESSAGE): {
        Util.sendMessage(slide.eventArgs[0], slide.eventArgs[1]);
        break;
      };

      case (EventType.SHARE): {
        openShareModal();
        break;
      };

      case (EventType.JUNGCHAEK): {
        ChannelService!.showMessenger();
        break;
      };

      case (EventType.COPY_LINK): {
        toast.info('링크가 클립보드에 복사되었습니다');
    
        Util.copyText(slide.eventArgs[0]);
        break;
      };

      case (EventType.COPY_ADDRESS): {
        toast.info('주소가 클립보드에 복사되었습니다');
    
        Util.copyText(slide.eventArgs[0]);
        break;
      };

      case (EventType.NEWS_LINK): {
        openLink(slide.eventArgs[0]);
        break;
      };

      case (EventType.VIDEO_LINK): {
        openLink(slide.eventArgs[0]);
        break;
      };

    }
  }, []);

  return (
    <>
    {(PageStore.page && PageStore.activeCardNewsSlideGroup) && (
      <>
      <Container currentPageMenu={currentPageMenu} >
        {PageStore.page.cardNewsSlideGroups.map((cardNewsSlideGroup, idx) => (
          <div key={idx}>

            <Typography variant="h6" className="mt-5 mx-[20px] font-black italic text-[#333]">{cardNewsSlideGroup.name}</Typography>

            <div className="aspect-[1/1] w-full leading-[0]">
              <MainSwiper modules={[Pagination]} spaceBetween={20} pagination={{dynamicBullets: true}} onInit={(core: any) => {swiperRef.current[idx] = core;doRerender()}}
                onSlideChange={() => doRerender()}>
                {cardNewsSlideGroup.cardNewsSlides.map((cardNewsSlide, sidx) => (
                  <SwiperSlide key={sidx}>
                    <Image src={cardNewsSlide.url} layout="fill" objectFit="contain" alt={`메인 이미지 ${sidx}`} />
                  </SwiperSlide>
                ))}
              </MainSwiper>
              {/* <MainSwiper modules={[Pagination]} spaceBetween={20} pagination={{dynamicBullets: true}} key={PageStore.activeCardNewsSlideGroup.id}
                onInit={(core: any) => {swiperRef.current = core;doRerender()}} onSlideChange={() => doRerender()}>
                {[...(PageStore.activeCardNewsSlideGroup.id === PageStore.page.cardNewsSlideGroups[0].id ? [defaultCardNewsSlide] : []), ...PageStore.activeCardNewsSlideGroup.cardNewsSlides].map((cardNewsSlide, idx) => (
                  <SwiperSlide key={idx}>
                    <Image src={cardNewsSlide.url} layout="fill" objectFit="contain" alt={`메인 이미지 ${idx}`} />
                  </SwiperSlide>
                ))}
              </MainSwiper> */}
            </div>

            {activeSlide?.[idx] && (
              <div className={`relative top-[-1px] flex leading-[0] text-left justify-between items-center z-10 px-4 h-[40px] bg-[#8ac452] w-full
              ${{
                [EventType.BLOG_LINK]: null,
                [EventType.FACEBOOK_LINK]: null,
                [EventType.GOOKHIM_LINK]: null,
                [EventType.HOMEPAGE_LINK]: null,
                [EventType.HOMESCREEN]: null,
                [EventType.INSTAGRAM_LINK]: null,
                [EventType.JUNG_LINK]: null,
                [EventType.KAKAOSTORY_LINK]: null,
                [EventType.BANKOPEN]: null,
                [EventType.YOUTUBE_LINK]: null,
                [EventType.QRCODE]: null,
                [EventType.MINJOO_LINK]: null,
                [EventType.NONE]: 'bg-[#e6e4da]',
                [EventType.SHARE]: 'bg-[#ffe900]',
                [EventType.OPEN_IMAGE]: 'bg-[#faa500]',
                [EventType.PHONE_CALL]: 'bg-[#02a240]',
                [EventType.SEND_MESSAGE]: 'bg-[#039dd8]',
                [EventType.BUY_LINK]: 'bg-[#0abab5]',
                [EventType.JUNGCHAEK]: 'bg-[#003668]',
                [EventType.DIRECT_LINK]: 'bg-[#000]',
                [EventType.SURVEY_LINK]: 'bg-[#0c1944]',
                [EventType.COPY_LINK]: 'bg-[#0c1944]',
                [EventType.VIDEO_LINK]: 'bg-[#0c1944]',
                [EventType.COPY_ADDRESS]: 'bg-[#0c1944]',
                [EventType.NEWS_LINK]: 'bg-[#0c1944]',
              }[activeSlide[idx].eventType || EventType.NONE] || 'bg-black'}`}
                onClick={() => doSlideEvent(activeSlide[idx])}
                >
                  <div className="flex items-center gap-2">
                    <div>
                      🔗
                    </div>

                    <Typography variant="subtitle2" className={`font-bold
                    ${{
                      [EventType.BLOG_LINK]: null,
                      [EventType.FACEBOOK_LINK]: null,
                      [EventType.GOOKHIM_LINK]: null,
                      [EventType.HOMEPAGE_LINK]: null,
                      [EventType.HOMESCREEN]: null,
                      [EventType.INSTAGRAM_LINK]: null,
                      [EventType.JUNG_LINK]: null,
                      [EventType.KAKAOSTORY_LINK]: null,
                      [EventType.BANKOPEN]: null,
                      [EventType.YOUTUBE_LINK]: null,
                      [EventType.QRCODE]: null,
                      [EventType.MINJOO_LINK]: null,
                      [EventType.NONE]: 'text-[#e6e4da]',
                      [EventType.SHARE]: 'text-[#003668]',
                      [EventType.OPEN_IMAGE]: 'text-[#003668]',
                      [EventType.PHONE_CALL]: 'text-[#ffffff]',
                      [EventType.SEND_MESSAGE]: 'text-[#000]',
                      [EventType.BUY_LINK]: 'text-[#000]',
                      [EventType.JUNGCHAEK]: 'text-[#fff]',
                      [EventType.DIRECT_LINK]: 'text-[#fff]',
                      [EventType.SURVEY_LINK]: 'text-[#fff]',
                      [EventType.COPY_LINK]: 'text-[#fff]',
                      [EventType.COPY_ADDRESS]: 'text-[#fff]',
                      [EventType.VIDEO_LINK]: 'text-[#fff]',
                      [EventType.NEWS_LINK]: 'text-[#fff]',
                    }[activeSlide[idx].eventType || EventType.NONE] || 'text-black'}
                    `}>{{
                      [EventType.BLOG_LINK]: null,
                      [EventType.FACEBOOK_LINK]: null,
                      [EventType.GOOKHIM_LINK]: null,
                      [EventType.HOMEPAGE_LINK]: null,
                      [EventType.HOMESCREEN]: null,
                      [EventType.INSTAGRAM_LINK]: null,
                      [EventType.JUNG_LINK]: null,
                      [EventType.KAKAOSTORY_LINK]: null,
                      [EventType.BANKOPEN]: null,
                      [EventType.YOUTUBE_LINK]: null,
                      [EventType.QRCODE]: null,
                      [EventType.MINJOO_LINK]: null,
                      [EventType.NONE]: '',
                      [EventType.SHARE]: '공유하기',
                      [EventType.OPEN_IMAGE]: '이미지보기',
                      [EventType.PHONE_CALL]: '전화연결',
                      [EventType.SEND_MESSAGE]: '문자보내기',
                      [EventType.BUY_LINK]: '구매하기',
                      [EventType.JUNGCHAEK]: '1:1 상담문의',
                      [EventType.DIRECT_LINK]: '바로가기',
                      [EventType.SURVEY_LINK]: '설문조사',
                      [EventType.COPY_LINK]: '링크복사',
                      [EventType.VIDEO_LINK]: '전체영상보기',
                      [EventType.COPY_ADDRESS]: '주소복사',
                      [EventType.NEWS_LINK]: '뉴스보기',
                    }[activeSlide[idx].eventType || EventType.NONE]}</Typography>
                  </div>

                  <KeyboardArrowRightIcon className={`
                    ${{
                      [EventType.BLOG_LINK]: null,
                      [EventType.FACEBOOK_LINK]: null,
                      [EventType.GOOKHIM_LINK]: null,
                      [EventType.HOMEPAGE_LINK]: null,
                      [EventType.HOMESCREEN]: null,
                      [EventType.INSTAGRAM_LINK]: null,
                      [EventType.JUNG_LINK]: null,
                      [EventType.KAKAOSTORY_LINK]: null,
                      [EventType.BANKOPEN]: null,
                      [EventType.YOUTUBE_LINK]: null,
                      [EventType.QRCODE]: null,
                      [EventType.MINJOO_LINK]: null,
                      [EventType.NONE]: 'text-[#e6e4da]',
                      [EventType.SHARE]: 'text-[#003668]',
                      [EventType.OPEN_IMAGE]: 'text-[#003668]',
                      [EventType.PHONE_CALL]: 'text-[#ffffff]',
                      [EventType.SEND_MESSAGE]: 'text-[#000]',
                      [EventType.BUY_LINK]: 'text-[#000]',
                      [EventType.JUNGCHAEK]: 'text-[#fff]',
                      [EventType.DIRECT_LINK]: 'text-[#fff]',
                      [EventType.SURVEY_LINK]: 'text-[#fff]',
                      [EventType.COPY_LINK]: 'text-[#fff]',
                      [EventType.COPY_ADDRESS]: 'text-[#fff]',
                      [EventType.VIDEO_LINK]: 'text-[#fff]',
                      [EventType.NEWS_LINK]: 'text-[#fff]',
                    }[activeSlide[idx].eventType || EventType.NONE] || 'text-black'}`} />
              </div>
            )}

            {isDescriptionTruncated && (
              <div className="p-4 mb-40 mt-4">
                <span className={`inline-block w-full overflow-hidden text-[0.9rem] break-words whitespace-pre-wrap ${isDescriptionTruncated[idx] && 'max-h-[40px]'}`} ref={(ref: any) => {
                  if (ref?.offsetHeight < 40) {
                    updateIsDescriptionTruncated(idx, false);
                  }
                }}>
                  {cardNewsSlideGroup.comment}
                </span>
                
                {isDescriptionTruncated[idx] && <span>... <span className="text-[#787878] text-[0.8rem]" onClick={() => updateIsDescriptionTruncated(idx, false)}>더보기</span></span>}
              </div>
            )} 

            {currentPageMenu === PageMenu["포트폴리오"] && (
              <>
              {/* <div className="bg-black mx-auto w-[80%] h-[40px] mt-5 flex items-center justify-center active:bg-gray-500">
                <Typography variant="subtitle1" className="text-white font-black">카드뉴스 목록</Typography>

                <select className="block absolute left-0 top-0 w-full h-full opacity-[0]" value={PageStore.page.cardNewsSlideGroups.findIndex(cardNewsSlideGroup => cardNewsSlideGroup.id === PageStore.activeCardNewsSlideGroup!.id)} onChange={onCardNewsChange}
                >
                  {PageStore.page.cardNewsSlideGroups.map((cardNewsSlideGroup, idx) => (
                    <option value={idx} key={idx}>{cardNewsSlideGroup.name}</option>
                  ))}
                </select>
              </div> */}
              </>
            )}

            
          </div>
        ))}
      </Container>
      </>
    )}
    </>
  );
}

export default observer(세번째페이지);