const EventType = {
  NONE: "NONE",
  YOUTUBE_LINK: "YOUTUBE_LINK",
  INSTAGRAM_LINK: "INSTAGRAM_LINK",
  HOMEPAGE_LINK: "HOMEPAGE_LINK",
  FACEBOOK_LINK: "FACEBOOK_LINK",
  KAKAOSTORY_LINK: "KAKAOSTORY_LINK",
  BLOG_LINK: "BLOG_LINK",
  MINJOO_LINK: "MINJOO_LINK",
  GOOKHIM_LINK: "GOOKHIM_LINK",
  JUNG_LINK:"JUNG_LINK",
  SHARE: "SHARE",
  HOMESCREEN: "HOMESCREEN",
  QRCODE: "QRCODE",
  BANKOPEN: "BANKOPEN",
  JUNGCHAEK: "JUNGCHAEK",
  PHONE_CALL: "PHONE_CALL",
  OPEN_IMAGE: "OPEN_IMAGE",
  SEND_MESSAGE: "SEND_MESSAGE",
  BUY_LINK: "BUY_LINK",
  DIRECT_LINK: "DIRECT_LINK",
  SURVEY_LINK: "SURVEY_LINK",
  COPY_LINK: "COPY_LINK",
  NEWS_LINK: "NEWS_LINK",
  VIDEO_LINK: "VIDEO_LINK",
  COPY_ADDRESS: "COPY_ADDRESS",
} as const;

type EventType = typeof EventType[keyof typeof EventType];

export default EventType;